import { mergeClasses } from '@expo/styleguide';
import { AndroidIcon } from '@expo/styleguide-icons/custom/AndroidIcon';
import { AppleAppStoreIcon } from '@expo/styleguide-icons/custom/AppleAppStoreIcon';
import { AppleIcon } from '@expo/styleguide-icons/custom/AppleIcon';
import { AppleSimulatorIcon } from '@expo/styleguide-icons/custom/AppleSimulatorIcon';
import { BadgeIcon } from '@expo/styleguide-icons/custom/BadgeIcon';
import { GoogleAppStoreIcon } from '@expo/styleguide-icons/custom/GoogleAppStoreIcon';
import { Cube01Icon } from '@expo/styleguide-icons/outline/Cube01Icon';
import { isAfter } from 'date-fns/isAfter';
import type { ReactNode } from 'react';

import {
  AppPlatform,
  BuildFragment,
  BuildMode,
  BuildPublicDataFragment,
  BuildStatus,
  DistributionType,
  TableBuildFragment,
  TableDevelopmentBuildFragment,
} from '~/graphql/types.generated';
import { TooltipContent } from '~/ui/components/Tooltip/TooltipContent';
import { TooltipRoot } from '~/ui/components/Tooltip/TooltipRoot';
import { TooltipText } from '~/ui/components/Tooltip/TooltipText';
import { TooltipTrigger } from '~/ui/components/Tooltip/TooltipTrigger';

export function getDistributionAndIcon(
  build: Pick<BuildFragment, 'platform' | 'distribution' | 'isForIosSimulator'>
): {
  distributionLabel?: string;
  distributionIcon?: ReactNode;
} {
  const { distribution, isForIosSimulator } = build;

  if (isForIosSimulator) {
    return {
      distributionIcon: <AppleSimulatorIcon className="icon-sm" />,
      distributionLabel: 'simulator',
    };
  } else if (distribution === DistributionType.Store) {
    return build.platform === AppPlatform.Ios
      ? {
          distributionIcon: <AppleAppStoreIcon className="icon-sm" />,
          distributionLabel: 'App Store',
        }
      : {
          distributionIcon: <GoogleAppStoreIcon className="icon-sm" />,
          distributionLabel: 'Play Store',
        };
  } else if (distribution === DistributionType.Internal) {
    return {
      distributionIcon: <BadgeIcon className="icon-sm" />,
      distributionLabel: 'internal distribution',
    };
  } else {
    return {
      distributionLabel: undefined,
      distributionIcon: undefined,
    };
  }
}

export function getBuildStatusFlags(status: BuildStatus) {
  return {
    isNew: status === BuildStatus.New,
    isQueued: status === BuildStatus.InQueue,
    isInProgress: status === BuildStatus.InProgress,
    isCanceled: status === BuildStatus.Canceled || status === BuildStatus.PendingCancel,
    isFinished: status === BuildStatus.Finished,
    isFailed: status === BuildStatus.Errored,
    isCancelable: status === BuildStatus.InQueue || status === BuildStatus.InProgress,
  };
}

export function getBuildStatus(status: BuildStatus) {
  const { isNew, isQueued, isInProgress, isCanceled, isFinished } = getBuildStatusFlags(status);

  if (isNew) {
    return 'New';
  } else if (isQueued) {
    return 'Queued';
  } else if (isInProgress) {
    return 'Building';
  } else if (isCanceled) {
    return 'Canceled';
  } else if (isFinished) {
    return 'Finished';
  } else {
    return 'Failed';
  }
}

export function getBuildTitle(
  build: Pick<
    BuildFragment,
    | 'platform'
    | 'message'
    | 'distribution'
    | 'buildMode'
    | 'customWorkflowName'
    | 'appBuildVersion'
    | 'appVersion'
    | 'isForIosSimulator'
  >,
  {
    showMessage = true,
    showVersionNumber = false,
    customDistribution,
  }: { showMessage?: boolean; showVersionNumber?: boolean; customDistribution?: string } = {}
) {
  const platform = build.platform === AppPlatform.Android ? 'Android' : 'iOS';
  const distribution = getDistributionAndIcon(build);

  const showBuildMessage = showMessage && build.message;
  const showBuildVersionNumber = showVersionNumber && build.appBuildVersion;
  const showCustomWorkflowName = build.buildMode === BuildMode.Custom && build.customWorkflowName;

  // build a string version of the non-tooltip text below

  let buildTitleString = '';
  buildTitleString += platform;
  if (distribution?.distributionLabel) {
    buildTitleString += ` ${distribution?.distributionLabel}`;
  }

  if (build.buildMode === BuildMode.Custom) {
    buildTitleString += ' custom';
  }

  buildTitleString += ' build';

  if (showBuildVersionNumber) {
    if (build.appVersion) {
      buildTitleString += ` ${build.appVersion}`;
    }
    if (build.appBuildVersion) {
      buildTitleString += ` (${build.appBuildVersion})`;
    }
  }

  if (showCustomWorkflowName) {
    buildTitleString += ` (${build.customWorkflowName})`;
  }

  if (showBuildMessage) {
    buildTitleString += `: "${build.message}"`;
  }

  return {
    buildTitleString,
    buildTitleElement: (
      <>
        {platform} {customDistribution ?? distribution?.distributionLabel}
        {showCustomWorkflowName ? ' custom' : ''} build
        {showBuildVersionNumber && (
          <>
            {' '}
            <TooltipRoot>
              <TooltipTrigger>
                {build.appVersion ? ` ${build.appVersion}` : null}
                {build.appBuildVersion ? ` (${build.appBuildVersion})` : null}
              </TooltipTrigger>
              <TooltipContent>
                <TooltipText>
                  {build.appVersion && (
                    <>
                      Version: {build.appVersion}
                      <br />
                    </>
                  )}
                  {build.platform === AppPlatform.Android ? 'Version code' : 'Build number'}:{' '}
                  {build.appBuildVersion}
                </TooltipText>
              </TooltipContent>
            </TooltipRoot>
          </>
        )}
        {showCustomWorkflowName && ` (${build.customWorkflowName})`}
        {showBuildMessage && `: "${build.message}"`}
      </>
    ),
  };
}

export function getPlatformDisplayNameAndIcon(
  platform: AppPlatform,
  className?: string
): {
  platformIcon: JSX.Element;
  platformDisplayName: string;
} {
  const platformDisplayName = {
    [AppPlatform.Ios]: 'iOS',
    [AppPlatform.Android]: 'Android',
  }[platform];

  const IconElement = getPlatformIcon(platform);
  const platformIcon = <IconElement className={mergeClasses('icon-sm', className)} />;

  return { platformIcon, platformDisplayName };
}

export function getPlatformIcon(platform: AppPlatform) {
  switch (platform) {
    case AppPlatform.Android:
      return AndroidIcon;
    case AppPlatform.Ios:
      return AppleIcon;
    default:
      return Cube01Icon;
  }
}

export function isBuildComplete(status: BuildStatus) {
  return [
    BuildStatus.Errored,
    BuildStatus.PendingCancel,
    BuildStatus.Canceled,
    BuildStatus.Finished,
  ].includes(status);
}

export function isBuildExpired(
  build:
    | BuildFragment
    | BuildPublicDataFragment
    | TableDevelopmentBuildFragment
    | TableBuildFragment,
  currentDateAsString?: string
) {
  return 'expirationDate' in build && build.expirationDate
    ? isAfter(
        currentDateAsString ? new Date(currentDateAsString) : new Date(),
        new Date(build.expirationDate)
      )
    : false;
}

export function isBuild(
  build:
    | BuildFragment
    | BuildPublicDataFragment
    | TableDevelopmentBuildFragment
    | TableBuildFragment
): build is BuildFragment {
  return build.__typename === 'Build';
}

const KNOWN_EXTENSIONS = ['apk', 'ipa', 'aab'];

export function getArtifactExtension(buildUrl?: string) {
  const extension = buildUrl?.split('.').pop() ?? '';

  if (KNOWN_EXTENSIONS.includes(extension)) {
    return extension;
  } else if (extension === 'gz') {
    return 'app';
  }
  return undefined;
}

export function getApplicationArchiveURL(
  build: BuildFragment | BuildPublicDataFragment | TableDevelopmentBuildFragment
) {
  return build.platform === AppPlatform.Ios
    ? `itms-services://?action=download-manifest;url=${process.env.API_SERVER_URL}/v2/projects/${build.app?.id}/builds/${build.id}/manifest.plist`
    : build.artifacts?.applicationArchiveUrl;
}
