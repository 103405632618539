// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { AppDataFragmentDoc } from './AppData.fragment.generated';
export type TableBuildFragment = { __typename: 'Build', id: string, activityTimestamp: any, createdAt: any, channel?: string | null, message?: string | null, expirationDate?: any | null, distribution?: Types.DistributionType | null, buildMode?: Types.BuildMode | null, customWorkflowName?: string | null, buildProfile?: string | null, gitRef?: string | null, appBuildVersion?: string | null, appVersion?: string | null, developmentClient?: boolean | null, isForIosSimulator: boolean, buildPlatform: Types.AppPlatform, buildStatus: Types.BuildStatus, buildRuntimeVersion?: string | null, buildGitCommitHash?: string | null, buildGitCommitMessage?: string | null, buildIsGitWorkingTreeDirty?: boolean | null, actor?: { __typename: 'Robot', id: string, displayName: string } | { __typename: 'SSOUser', profilePhoto: string, id: string, displayName: string } | { __typename: 'User', profilePhoto: string, id: string, displayName: string } | null, app: { __typename: 'App', id: string, iconUrl?: string | null, fullName: string, name: string, slug: string, lastDeletionAttemptTime?: any | null, icon?: { __typename?: 'AppIcon', url: string, primaryColor?: string | null } | null, ownerAccount: { __typename?: 'Account', name: string, id: string }, githubRepository?: { __typename?: 'GitHubRepository', githubRepositoryUrl?: string | null } | null }, metrics?: { __typename?: 'BuildMetrics', buildDuration?: number | null } | null };

export const TableBuildFragmentDoc = gql`
    fragment TableBuild on Build {
  id
  __typename
  activityTimestamp
  createdAt
  actor {
    id
    __typename
    displayName
    ... on UserActor {
      profilePhoto
    }
  }
  app {
    ...AppData
  }
  channel
  buildPlatform: platform
  buildStatus: status
  buildRuntimeVersion: runtimeVersion
  buildGitCommitHash: gitCommitHash
  buildGitCommitMessage: gitCommitMessage
  buildIsGitWorkingTreeDirty: isGitWorkingTreeDirty
  message
  expirationDate
  distribution
  buildMode
  customWorkflowName
  buildProfile
  gitRef
  appBuildVersion
  appVersion
  metrics {
    buildDuration
  }
  developmentClient
  isForIosSimulator
}
    ${AppDataFragmentDoc}`;