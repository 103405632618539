const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

export const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / HOUR);
  const minutes = Math.floor((duration % HOUR) / MINUTE);
  const seconds = Math.floor((duration % MINUTE) / SECOND);

  if (hours) {
    return `${hours}h ${minutes}m ${seconds}s`;
  } else if (minutes) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
};
